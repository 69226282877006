/* Google Fonts import */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

a {
  text-decoration: none;
}

/* Slick carousel özelleştirmeleri */
.slick-dots li button:before {
  font-size: 12px;
  color: #1976d2;
  opacity: 0.5;
}

.slick-dots li.slick-active button:before {
  color: #1976d2;
  opacity: 1;
}

/* Scroll to top button */
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #1976d2;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.scroll-to-top:hover {
  background: #115293;
  transform: translateY(-5px);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.6s ease-out forwards;
}